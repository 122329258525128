import React from "react";
import pdffile from "../../assets/downloadpdf/Company&Products.pdf";
import pdffile2 from "../../assets/downloadpdf/Product.pdf";
import pdffile3 from "../../assets/downloadpdf/EgsLighthouseNewsletter.pdf";

export default function Downloads() {
  return (
    <>
      <section className="download-services">
        <div className="container  pb-40">
          <div className="row justify-content-center">
            <div className="col-lg-8"></div>
          </div>
          <div className="row justify-content-center ">
            <div className="col-lg-4 service-card-ribben ">
              <div className="ribbon ribbon-top-left">
                <span>New</span>
              </div>
              {/* <div className="service-seven"> */}
              <div className="service-item-seven service-card">
                <div style={{ textAlignLast: "center"}}>
                  <img
                    src={
                      require(`../../assets/images/logos/elena-site-logo-558_x_106-removebg-preview.png`)
                        .default
                    }
                    alt="Service"
                    style={{ height: "80px" }}
                  />
                </div>
                <h4 className="mt-4 service-text">Lighthouse News Letter</h4>
                <h5 className="text-muted mt-2">Go Brief About News Letter</h5>
                <a
                  href={pdffile3}
                  target="_blank"
                  rel="noreferrer"
                  className="read-more float-right px-25"
                >
                  Download Here <i className="fas fa-long-arrow-alt-down"></i>
                </a>
              </div>
            </div>
            <div className="col-lg-4 ">
              {/* <div className="service-seven"> */}

              <div className="service-item-seven service-card">
              <div style={{ textAlignLast: "center"}}>
                  <img
                    src={
                      require(`../../assets/images/logos/elena-site-logo-558_x_106-removebg-preview.png`)
                        .default
                    }
                    alt="Service"
                    style={{ height: "80px" }}
                  />
                </div>
                <h4 className="mt-4 service-text">Company Brochure</h4>
                <h5 className="text-muted mt-2">Go Brief About The Company</h5>
                <a
                  href={pdffile}
                  target="_blank"
                  rel="noreferrer"
                  className="read-more float-right px-25"
                >
                  Download Here <i className="fas fa-long-arrow-alt-down"></i>
                </a>
              </div>
            </div>
            {/* </div> */}
            <div className="col-lg-4 ">
              <div className="service-seven">
                <div className="service-item-seven service-card">
                <div style={{ textAlignLast: "center"}}>
                  <img
                    src={
                      require(`../../assets/images/logos/elena-site-logo-558_x_106-removebg-preview.png`)
                        .default
                    }
                    alt="Service"
                    style={{ height: "80px" }}
                  />
                </div>
                  <h4 className="mt-4 service-text">Product Brochure</h4>
                  <h5 className="text-muted mt-2">
                    Go Brief About The Product
                  </h5>
                  <a
                    href={pdffile2}
                    target="_blank"
                    rel="noreferrer"
                    className="read-more float-right px-25"
                  >
                    Download Here <i className="fas fa-long-arrow-alt-down"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="more-about-services mt-50">

                    </div> */}
        </div>
      </section>
    </>
  );
}
