import axios from "axios";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import background from "../../assets/images/contact/contact-bg.jpg";
import Layouts from "../helpers/Layouts";
import Branches from "./Branches";

export default function Contact() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [from, setFrom] = useState("");
  const [remarks, setRemarks] = useState("");
  const [btnName, setBtnName] = useState("Send Message");
  const [errors, setErrors] = useState({});

  const nav = useNavigate();

  useEffect(() => {
    // Define a function for form validation
    function validateForm() {
      const newErrors = {};

      if (!name.trim()) {
        // newErrors.name = "Name is required";
      } else if (!/^[A-Za-z\s]+$/.test(name)) {
        newErrors.name = "Name should not contain numbers";
      }

      if (!email.trim()) {
        // newErrors.email = "Email is required";
      } else if (!/^\S+@\S+\.\S+$/.test(email)) {
        newErrors.email = "Invalid email format";
      }

      if (!phoneNumber.trim()) {
        // newErrors.phoneNumber = "Phone Number is required";
      } else if (!/^[1-9][0-9]{9}$/.test(phoneNumber)) {
        newErrors.phoneNumber = "Invalid phone number format";
      }

      if (!from.trim()) {
        // newErrors.from = "";
      } else if (!from) {
        newErrors.from = "Company/From is required";
      }

      if (!remarks.trim()) {
        // newErrors.remarks = "Remarks are required";
      }

      // Update the errors state with the new validation results
      setErrors(newErrors);
    }

    // Call the validation function whenever form fields change
    validateForm();
  }, [name, email, phoneNumber, from, remarks]);

  function handleNameOnChange(e) {
    setName(e.target.value);
    console.log(name);
  }
  function handleEmailOnChange(e) {
    setEmail(e.target.value);
  }

  function handlePhoneNumberOnChange(e) {
    setPhoneNumber(e.target.value);
  }

  function handleFromOnChange(e) {
    setFrom(e.target.value);
  }
  function handleRemarksOnChange(e) {
    setRemarks(e.target.value);
  }

  // POSTING THE DATA TO POSTMAN

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (Object.keys(errors).length === 0) {
      setBtnName("Sending...");
      const formData = {
        name,
        email,
        mobile: phoneNumber,
        query: from,
        remarks,
      };
      try {
        const res = await axios.post(
          "https://enquiry.elenageosys.com/enquiry/",
          formData
        );
        console.log(res);
        nav("/contact-thanku");
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <>
      <Layouts pageTitle="Contact us" breadcrumbs={[]} background={background}>
        <section className="contact-page py-120 rpy-100" id="contactForm">
          <div className="container">
            <div className="contact-info-area mb-80">
              <div className="contact-info-item wow fadeInUp delay-0-2s">
                <Link to="https://maps.app.goo.gl/oUQMkystQmQU6ys5A">
                  <i className="far fa-map"></i>
                  <p>
                    62/1, 1st cross, 2nd main, Ganganagar, Bangalore. 560032
                  </p>
                </Link>
              </div>
              <div className="contact-info-item wow fadeInUp delay-0-4s">
                <i className="far fa-envelope"></i>
                <p className="contact-mail">
                  {/* <a href="mailto:sales@elenageosystems.com">sales@elenageosystems.com</a>{" "}
                  <br /> */}
                  <a href="mailto:info@elenageo.com">info@elenageo.com</a>
                </p>
              </div>
              <div className="contact-info-item wow fadeInUp delay-0-6s">
                <i className="fas fa-phone-alt"></i>
                <p>
                  <a href="callto:+919384864411">+919384864411</a> <br />
                  <a href="callto:+919384864422">+919384864422</a>
                </p>
              </div>
            </div>
            <Branches />
            <div className="row">
              <div className="col-lg-4">
                <div
                  className="contact-form-left bgs-cover h-100 wow fadeInLeft delay-0-2s"
                  style={{ backgroundImage: `url(${background})` }}
                >
                  <h2>Leave A Message</h2>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="contact-form ml-40 rml-0 rmt-55 wow fadeInRight delay-0-2s">
                  <h3 className="comment-title mb-35">Send A Message</h3>
                  <p>
                    Want to get in touch? we'd love to hear from you ,Here's how
                    you can contact us...
                  </p>
                  <form
                    id="contact-form"
                    className="contact-form"
                    name="contact-form"
                    onSubmit={handleSubmit}
                  >
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="name">Your name</label>
                          <input
                            type="text"
                            id="name"
                            name="name"
                            className={`form-control ${
                              errors.name ? "is-invalid" : ""
                            }`}
                            placeholder="Name"
                            onChange={handleNameOnChange}
                            required
                            value={name}
                          />
                          {errors.name && (
                            <div className="invalid-feedback">
                              {errors.name}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="email">Your email address</label>
                          <input
                            type="email"
                            id="email"
                            name="email"
                            className={`form-control ${
                              errors.email ? "is-invalid" : ""
                            }`}
                            placeholder="Email"
                            required
                            onChange={handleEmailOnChange}
                            value={email}
                          />
                          {errors.email && (
                            <div className="invalid-feedback">
                              {errors.email}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="number">Your phone number</label>
                          <input
                            type="text"
                            id="number"
                            name="number"
                            className={`form-control ${
                              errors.phoneNumber ? "is-invalid" : ""
                            }`}
                            placeholder="Phone Number"
                            required
                            pattern="[1-9]{1}[0-9]{9}"
                            title="Please enter 10 digits mobile number"
                            maxLength={10}
                            onChange={handlePhoneNumberOnChange}
                            value={phoneNumber}
                          />
                          {errors.phoneNumber && (
                            <div className="invalid-feedback">
                              {errors.phoneNumber}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="from">From</label>
                          <input
                            type="text"
                            id="from"
                            name="from"
                            className={`form-control ${
                              errors.from ? "is-invalid" : ""
                            }`}
                            placeholder="Company / From"
                            required
                            onChange={handleFromOnChange}
                            value={from}
                          />
                          {errors.from && (
                            <div className="invalid-feedback">
                              {errors.from}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <div className="form-group">
                          <textarea
                            name="comments"
                            id="comments"
                            className={`form-control ${
                              errors.remarks ? "is-invalid" : ""
                            }`}
                            rows="4"
                            placeholder="Let us know what you need."
                            required
                            onChange={handleRemarksOnChange}
                            value={remarks}
                          >
                            {errors.remarks && (
                              <div className="invalid-feedback">
                                {errors.remarks}
                              </div>
                            )}
                          </textarea>
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <button
                          type="submit"
                          className="theme-btn   btn btn-primary mb-2"
                        >
                          {btnName}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="contact-page-map">
          <div className="our-location">
            <iframe
              title="map"
              height="650"
              style={{ border: "0", width: "100%" }}
              loading="lazy"
              // allowfullscreen
              referrerPolicy="no-referrer-when-downgrade"
              src="https://www.google.com/maps/embed/v1/place?key=AIzaSyBSir-VXONLZsZ5lA4Dzcqct0ZSnAlwvSs
                &q=Elena+Geo+Systems,Ganganagar+Bangalore"
            ></iframe>
          </div>
        </div>
      </Layouts>
    </>
  );
}
