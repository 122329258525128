import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../../../assets/images/logos/elena-site-logo-558_x_106-removebg-preview.png";

function FooterHomeOne() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [email, setEmail] = useState("");
  const [isEmailExists, setIsEmailExists] = useState(false);
  const nav = useNavigate();

  function handleEmailOnChange(e) {
    setEmail(e.target.value);
    setIsEmailExists(false);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = {
      email,
    };
    try {
      const res = await axios.post(
        "https://enquiry.elenageosys.com/subscriber/",
        formData
      );
      console.log(res);
      if (res.status === 201) {
        nav("/subscribe");
      }
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 400) {
        setIsEmailExists(true);
      } else {
        console.error("An error occurred while submitting the email.");
      }
    }
  };
  return (
    <footer className="main-footer footer-one text-white">
      <div className="footer-widget-area bgs-cover pt-80 pb-30">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-sm-6">
              <div className="footer-widget about-widget">
                <div className="footer-logo mb-35">
                  <Link to="/">
                    <img
                      src={logo}
                      alt="Logo"
                      style={{
                        height: "100px",
                        backgroundColor: "#ffffff",
                        padding: "10px 3px",
                        borderRadius: "10px",
                      }}
                    />
                  </Link>
                </div>
                <div className="footer-text">
                  Leading NavIC-based product manufacturing company, and
                  pioneers of innovative solutions using NavIC.
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-sm-6">
              <div className="footer-widget link-widget ml-20 rml-0">
                <h2 className="footer-title custom-h2">Page Links</h2>
                <ul className="list-style-two">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/about">About</Link>
                  </li>
                  <li>
                    <Link to="/contact">Contact</Link>
                  </li>
                  <li>
                    <Link to="/news">News</Link>
                  </li>
                  <li>
                    <Link to="/tracking-page">Tracking systems</Link>
                  </li>
                  <li>
                    <Link to="/quality-certification">
                      Quality Certification
                    </Link>
                  </li>
                  <li>
                    <Link to="/downloads">Downloads</Link>
                  </li>
                  <li>
                    <Link to="/faq">FAQ</Link>
                  </li>
                  {/* <li>
                    <Link to="/gallery">gallery</Link>
                  </li> */}
                  {/* <li>
                    <Link to="/events">Events</Link>
                  </li> */}
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="footer-widget contact-widget mr-30 rmr-0">
                <h2 className="footer-title custom-h2">Contacts</h2>
                <ul className="list-style-two">
                  <li>
                    <i className="fas fa-map-marker-alt"></i> 62/1, 1st cross,
                    2nd main, Ganganagar, Bangalore 560032
                  </li>
                  <li>
                    <i className="fas fa-clock"></i> Mon-Sat 9:00 - 6:00
                  </li>
                  <li>
                    <i className="fas fa-phone-alt"></i>
                    <a href="callto:+919384864411">
                      +91 9384864411<br></br> +91 9384864422{" "}
                    </a>
                  </li>
                  <li>
                    <i className="fas fa-envelope"></i>
                    <a
                      href="mailto:Info@elenageo.com"
                      style={{ textTransform: "lowercase" }}
                    >
                      Info@elenageo.com <br></br>{" "}
                    </a>
                  </li>
                  {/* <li>
                    <div className="">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        id="footer-svg"
                        height="1em"
                        viewBox="0 0 512 512"
                      >
                        <path d="M64 64c0-17.7-14.3-32-32-32S0 46.3 0 64V400c0 44.2 35.8 80 80 80H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H80c-8.8 0-16-7.2-16-16V64zm406.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L320 210.7l-57.4-57.4c-12.5-12.5-32.8-12.5-45.3 0l-112 112c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L240 221.3l57.4 57.4c12.5 12.5 32.8 12.5 45.3 0l128-128z" />
                      </svg>
                    </div>
                    <a
                      className="px-20 "
                      href="mailto:sales@elenageosystems.com"
                      style={{ textTransform: "lowercase" }}
                    >
                      sales@elenageosystems.com <br></br>{" "}
                    </a>
                  </li> */}
                </ul>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="footer-widget newsletter-widget">
                <h2 className="footer-title custom-h2">Newsletter</h2>
                <p>
                  Subscribe to our newsletter for the latest updates and news
                  delivered directly to your inbox
                </p>
                <form action="#" method="post" onSubmit={handleSubmit}>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Your Email Address"
                    required
                    onChange={handleEmailOnChange}
                    value={email}
                  />
                  <button type="submit" aria-label="send-news-letter">
                    <i className="fa fa-location-arrow"></i>
                  </button>
                </form>
                {isEmailExists && (
                  <p className="text-warning">You have already Subscribed</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright-area bg-blue">
        <div className="container">
          <div className="copyright-inner pt-15">
            <div className="social-style-one mb-10">
              <a
                href="https://www.facebook.com/people/Elena-Geo-Systems-Private-Limited/100063509753575/"
                aria-label="facebook"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-facebook-f"></i>
              </a>
              <a
                href="https://twitter.com/ElenaGeoSys"
                aria-label="twitter"
                target="_blank"
                rel="noreferrer"
              >
                {/* <i className="fab fa-twitter"></i> */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="1em"
                  viewBox="0 0 512 512"
                >
                  <path
                    id="time-3-icon"
                    d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"
                  />
                </svg>
              </a>
              <a
                href="
                https://instagram.com/elena_geosystems?igshid=MzRlODBiNWFlZA=="
                target="_blank"
                rel="noreferrer"
                aria-label="instagram"
              >
                <i className="fab fa-instagram"></i>
              </a>
              <a
                href="https://www.linkedin.com/company/elena-geo-systems-private-limited/"
                target="_blank"
                rel="noreferrer"
                aria-label="linkedin"
              >
                <i className="fab flaticon-linkedin-2"></i>
              </a>
            </div>
            <p>
              copyright &copy; 2023 Elena Geo Tech Pvt Ltd. All Rights
              Reserved.
            </p>
            <p>
              <Link to="/privacy-policy">Privacy Policy | </Link>
              <Link to="/terms">Terms of Use | </Link>
              <Link to="/eula">EULA</Link>
              {/* <Link to="/eula">EULA</Link> */}
            </p>
            {/* <p>
              <Link>Privacy Policy</Link>
            </p> */}
          </div>
        </div>
      </div>
    </footer>
  );
}

export default FooterHomeOne;
