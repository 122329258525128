import React from "react";
import { Link } from "react-router-dom";
import logoMain from "../../../../assets/images/logos/elena-site-logo-558_x_106-removebg-preview.png";
import Navigation from "../../../helpers/Navigation";
import MobileHeaderCom from "../../Mobile/MobileHeaderCom";

// import logoMakeInIndia from "../../../../assets/images/logos/make-in-India-logo.jpg";

function HeaderHomeOne() {
  // useEffect(() => {
  //   StickyMenu(".main-header");
  // });

  return (
    <>
      <MobileHeaderCom logo={logoMain} />
      <header className="main-header header-one ">
        {/* <div className="header-top bg-lighter py-10">
          <div className="top-left">
            <ul>
              <li> */}
                {/* Call Us: <a href="callto:+919384864411">+91 9384864411</a> */}
                {/* <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="1em"
                  viewBox="0 0 512 512"
                >
                  <path d="M64 64c0-17.7-14.3-32-32-32S0 46.3 0 64V400c0 44.2 35.8 80 80 80H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H80c-8.8 0-16-7.2-16-16V64zm406.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L320 210.7l-57.4-57.4c-12.5-12.5-32.8-12.5-45.3 0l-112 112c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L240 221.3l57.4 57.4c12.5 12.5 32.8 12.5 45.3 0l128-128z" />
                </svg>{" "}
                Sales : <a href="callto:+919384864422 ">+91 9384864422 </a>,{" "}
                <a href="mailto:sales@elenageosystems.com">
                  sales@elenageosystems.com
                </a>
              </li>
              <li>
                <i className="fas fa-envelope"></i> Info :{" "}
                <a href="callto:+919384864411">+91 9384864411 </a>,{" "}
                <a href="mailto:info@elenageosystems.com">
                  info@elenageosystems.com
                </a> */}
                {/* Email Us:{" "}
                <a href="mailto:info@elenageosystems.com">
                  info@elenageosystems.com
                </a> */}
              {/* </li> */}
              {/* <li>Our address: Bangalore</li> */}
            {/* </ul>
          </div>
          <div className="top-right"> */}
            {/* <div className="office-time">
              <i className="far fa-clock"></i>
              <span>09:00 am - 06:00 pm</span>
            </div> */}
            {/* <div className="social-style-one">
              <a
                href="https://www.facebook.com/people/Elena-Geo-Systems-Private-Limited/100063509753575/"
                aria-label="facebook"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-facebook-f"></i>
              </a>
              <a
                href="http://twitter.com/ElenaGeoSys"
                aria-label="twitter"
                target="_blank"
                rel="noreferrer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="1em"
                  viewBox="0 0 512 512"
                >
                  <path
                    id="time-2-icon"
                    d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"
                  />
                </svg>
              </a>
              <a
                href=" https://instagram.com/elena_geosystems?igshid=MzRlODBiNWFlZA=="
                target="_blank"
                rel="noreferrer"
                aria-label="instagram"
              >
                <i className="fab fa-instagram"></i>
              </a>
              <a
                href="https://www.linkedin.com/company/elena-geo-systems-private-limited/"
                target="_blank"
                rel="noreferrer"
                aria-label="linkedin"
              >
                <i className="fab flaticon-linkedin-2"></i>
              </a> */}
              {/* <a href="#">
                <img
                  src={logoMakeInIndia}
                  alt="Make in India Logo"
                  style={{ width: "40px" }}
                />
              </a> */}
            {/* </div>
          </div>
        </div> */}
        <div className="header-upper bg-white ">
          <div className="container-fluid clearfix ">
            <div className="header-inner d-flex align-items-center ">
              <div className="logo-outer  py-5 px-30">
                <div className="logo">
                  <Link to="/">
                    <img
                      src={logoMain}
                      alt="Logo"
                      title="Logo"
                      className="p-1"
                      style={{ width: "50px" }}
                    />
                  </Link>
                </div>
              </div>

              <div className="nav-outer clearfix d-flex align-items-center">
                <Navigation className="nav-home-one" />
                <div className="menu-btn">
                  <Link to="/contact" className="theme-btn">
                    Contact Us
                  </Link>
                  {/* <a href="#" className="menu-btn">
                <img
                  src={logoMakeInIndia}
                  alt="Make in India Logo"
                  style={{ width: "100px" }}
                  
                />
              </a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default HeaderHomeOne;
