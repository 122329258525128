import React from "react";
import teamProfile from "../../../assets/images/team/velan-sir-img.jpeg";
// import AboutBackground from "../../../assets/images/about/about-us-bg.jpg";

export default function MainSection() {
  return (
    <>
      {/* <section
        className="about-banner   overlay bgs-cover pt-190"
        style={{ backgroundImage: `url(${AboutBackground})` }}
      >
        <h1 className="text-white px-60 pb-170">About Us</h1>
      </section> */}

      <section className="team-profile pt-40 pb-30 rpy-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div className="team-profile-image rmb-45 wow fadeInLeft delay-0-2s">
                <div className=" mb-10 profile-name">
                  <h2 className="name ">Lt Col V S Velan</h2>
                  <span className="designation"> Founder</span>
                </div>
                <div className="team-profileimg">
                  <img className="team-profile" src={teamProfile} alt="Team Profile" />
                </div>
                <div className=" team-profile-content">
                  <h3>More about our founder</h3>
                  <div className="social-style-two mt-15">
                    <a href="https://www.facebook.com/people/Elena-Geo-Systems-Private-Limited/100063509753575/" target="_blank" rel="noreferrer">
                      <i className="fab fa-facebook-f"></i>
                    </a>
                    <a href="https://twitter.com" target="_blank" rel="noreferrer">
                      <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512" ><path id="time-2-icon" d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" /></svg>

                    </a>
                    <a href="https://instagram.com/elena_geosystems?igshid=MzRlODBiNWFlZA==" target="_blank" rel="noreferrer">
                      <i className="fab fa-instagram"></i>
                    </a>
                    <a href="https://www.linkedin.com/company/elena-geo-systems-private-limited/" target="_blank" rel="noreferrer">
                      <i className="fab flaticon-linkedin-2"></i>
                    </a>
                  </div>
                </div>

              </div>
            </div>
            <div className="col-lg-7">
              <div className="team-profile-content ml-10 rml-0 wow fadeInRight delay-0-2s">

                <p className="about-text mt-20">
                  Lt Col V S Velan, an Army Veteran, has dedicated himself for the proliferation of
                  satellite-based navigation using Indian constellation called NavIC. He has been
                  associated with this technology from 1998 and with NavIC from its genesis in 2008-
                  2010. Since 2010, he has been working only in this niche segment for which he had
                  to travel around the world gathering knowledge. While in service, Col Velan was
                  instrumental in establishing and developing the Ministry of Defence’s Integrated
                  Space Cell which has now grown into Space Forces of India.
                </p>
                <p className="about-text">
                  With his tremendous domain knowledge and expertise on GNSS, Col Velan
                  launched Elena Geo Tech in the Technology Business Incubator of IIT
                  Kharagpur. Elena is the only company working dedicatedly to develop indigenous
                  solutions for NavIC. Col Velan’s ceaseless drive to innovate has made Elena Geo
                  Systems a trailblazer in multi-GNSS devices and applications with an orientation to
                  use and deploy NavIC as its core. The company demonstrated its first NavIC module
                  on April 6, 2019, and its first GNSS processor on April 13, 2023.
                </p>
                <p className="about-text">
                  Elena’s accomplishments are many: it boasts of having all hardware and software to
                  provide end-to-end solution for using NavIC. The products include NavIC-based
                  antennae, drone navigation units, atomic clocks, tracking devices and severs among
                  others. All the devices were designed and developed in the spirit of Make in India.
                  Elena Geo Tech is the industrial partner for Bengaluru’s prestigious Ramaiah
                  Institute of Technology and IIT, Tirupati.
                </p>
                <p className="about-text">
                  In recognition of his ground-breaking work on NavIC, Col Velan was appointed as
                  a technology development advisor at IIT-Tirupati’s Navavishkar innovation Hub
                  Foundation in July 2023.
                </p>
                {/* <ul className="list-style-three pt-15 pb-30">
                <li>Lorem ipsum dolor sit amet, consectetur</li>
                <li>Lorem ipsum dolor sit amet, consectetur</li>
                <li>Lorem ipsum dolor sit amet, consectetur</li>
                <li>Lorem ipsum dolor sit amet, consectetur</li>
              </ul> */}


              </div>
            </div>
          </div>
        </div>
      </section>
    </>

  );
}
