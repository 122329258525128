import React from "react";
import qualityLogo from "../../assets/images/logos/elena-site-logo-558_x_106-removebg-preview.png";

function MainQualityCertification() {
  return (
    <>
      <section className="quality-certification-section py-30">
        <div className="quality-certification container">
          <h2 className="text-center wow fadeInUp delay-0-4s mb-20">
            Quality Certification
          </h2>
          <div className="row align-items-center">
            <div className="col-lg-6 mb-10">
              <div className="card">
                <div className="hero-content-five px-20 py-20">
                  <p>
                    To demonstrate our commitment to providing customers with
                    high-quality products and services, Elena follows certain
                    business process and standards to:
                  </p>
                  <ul className="list-style-three mt-15">
                    <li>
                      Ensure all Elena’s products and services provided follows
                      defined requirements.
                    </li>
                    <li>
                      Incorporate mechanisms, process and procedures that assist
                      in monitoring the effectiveness of the products and
                      services .
                    </li>
                    <li>Embed a programme of continual improvement.</li>
                  </ul>
                  <div className="row align-items-center quality-content-logo-container">
                    <div className="col-xl-12 col-md-6">
                      <div className=" wow fadeInUp delay-0-2s">
                        <div className="quality-section-logo">
                          <img src={qualityLogo} alt="Logo" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 mb-10">
              <div className="card px-20 py-25 quality-content about-content wow fadeInRight delay-0-2s">
                <div className="section-title">
                  <span className="sub-title">
                    Our Certification: ISO 9001:2015
                  </span>
                  <h5>
                    We are registered with the Ministry of Company Affairs, GOI,
                    under Company Identity Number (CIN) U72200KA2012PTC139939 (
                    Old CIN: U72200PN2012PTC145303 ).
                  </h5>
                  <p>
                    The ISO 9001 : 2015 certification helps Elena to develop and
                    improve performance, as well as demonstrate high levels of
                    service quality. This helps Elena to
                  </p>
                </div>

                <ul className="list-style-three mt-1">
                  <li>Operate more efficiently.</li>
                  <li>Meet statutory and regulatory requirements.</li>
                  <li>Reach new markets.</li>
                  <li>Meet requirements of its customers and stakeholders</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default MainQualityCertification;
